export default {
  "We have found the following address": "We hebben het volgende adres gevonden",
  "Load more reviews": "Bekijk meer reviews",
  "Bought product": "Product gekocht",
  "Tested product": "product getest",
  "Sponsored product": "product gesponsord",
  "Used product": "product gebruikt",
  "Something went wrong": "Er is iets misgegaan",
  "Invalid address": "Het ingevoerde adres is onbekend",
  "Your postal code": "Uw postcode",
  "Details": "Gegevens",
  "Shipping": "Verzending",
  "Shipping methods": "Verzendmethoden",
  "Payment": "Betalen",
  "Free shipping or pick up": "Gratis verzending of afhalen",
  "Shipping cost will be calculated in the next step": "Verzendkosten worden berekend tijdens het afrekenen",
  "Choose your bank": "Kies een bank",
  "Choose your creditcard": "Kies een creditcard",
  "Log in with your Expert account": "Log in met uw Expert account",
  "Buy without a Expert account": "Snel afrekenen zonder te registreren",
  "Email address": "E-mail",
  "Password": "Wachtwoord",
  "Confirm password": "Bevestig wachtwoord",
  "Create a new account": "Maak een nieuw account aan",
  "Create account": "Account aanmaken",
  "Log in": "Inloggen",
  "Forgot password?": "Wachtwoord vergeten?",
  "Yes, I would like to receive the newsletter": "Ja, ik wil de nieuwsbrief ontvangen",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Postal code": "Postcode",
  "City": "Plaats",
  "Street": "Straat",
  "House no.": "Huisnummer",
  "House number" : "Huisnummer",
  "House Nr.": "Huisnummer",
  "Add.": "Toevoeging",
  "Addition": "Toevoeging",
  "House Nr add.": "Toevoeging",
  "Company": "Bedrijfsnaam",
  "Telephone": "Telefoonnummer",
  "Order as a company": "Bestellen als een bedrijf",
  "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet u zeker dat het correct is?",
  "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
  "Please wait, loading shipping methods": "Even geduld, verzendmethoden worden geladen",
  "Billing address": "Factuuradres",
  "Billing address is different from the address above": "Factuuradres is anders dan het bovenstaande adres",
  "Back to cart": "Terug naar winkelwagen",
  "To shipping": "Naar verzending",
  "To payment": "Naar betaling",
  "Complete order": "Bestelling afronden",
  "Payment methods": "Betaalmethoden",
  "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Er is iets misgegaan, de betaling kon niet worden voltooid. Probeer het opnieuw of kies een andere betaalmethode.",
  "Please wait, loading payment methods": "Even geduld, betaalmethoden worden geladen",
  "Without extra fees": "Zonder extra kosten",
  "Overview of your order": "Overzicht van uw bestelling",
  "Shipping & Handling": "Verzending",
  "Please select a shipping method.": "Selecteer een verzendmethode of winkel.",
  "Change": "Wijzigen",
  "Free": "Gratis",
  "Your order will be delivered on": "Uw bestelling wordt bezorgd op",
  "Your order will be taken care of by": "Uw bestelling wordt verzorgd door",
  "This is a secure connection": "Dit is een veilige verbinding",
  "Compare products": "Vergelijk producten",
  "Compare product": "Vergelijk product",
  "Compare": "Vergelijk",
  "Product purchased": "Product gekocht",
  "Add product to cart": "Plaats in winkelwagen",
  "View delivery options and delivery times in your area": "Bekijk de bezorgopties en tijden in jouw buurt",
  "This postcode is unknown": "Deze postcode is onbekend",
  "Postcode": "Postcode",
  "Opening hours": "Openingstijden",
  "Monday": "maandag",
  "Tuesday": "dinsdag",
  "Wednesday": "woensdag",
  "Thursday": "donderdag",
  "Friday": "vrijdag",
  "Saturday": "zaterdag",
  "Sunday": "zondag",
  "Closed": "gesloten",
  "More about": "Meer over",
  "Ask for more information": "Stel een vraag",
  "Search for...": "Zoeken naar...",
  "Product is in stock": "Product is op voorraad",
  "Product is out of stock": "Product is niet op voorraad",
  "Find a Expert shop": "Vind uw Expert",
  "More then {stores} shops in the Netherlands": "Meer dan {stores} winkels in Nederland",
  "is mine Expert!": "is mijn Expert!",
  "km away from you.": "km bij mij vandaan.",
  "Visit our storepage": "Bezoek deze winkelpagina",
  "Choose other shop": "Kies een andere winkel",
  "No stores with stock found": "Geen winkels met voorraad gevonden",
  "All stores with stock": "Alle winkels met voorraad",
  "Current stores stock": "Huidige winkelvoorraad",
  "Please fill in a correct E-mail address": "E-mail moet een geldig e-mailadres zijn",
  "You are subscribed!": "Gelukt!",
  "You are sign up for our newsletter with {email}": "Je hebt je aangemeld met {email}",
  "Newsletter": "Nieuwsbrief",
  "Always up to date with the latest promotions": "Altijd op de hoogte van de nieuwste acties",
  "Searching": "Zoeken",
  "Hit enter or try another search.": "Druk op enter en probeer een andere zoekopdracht",
  "Show more": "Meer tonen",
  "Show less": "Minder tonen",
  "Go to the cart": "Naar winkelwagen",
  "Continue shopping": "Verder winkelen",
  "Your order has been successfully placed.": "Je bestelling is succesvol geplaatst.",
  "Matching accessories": "Bijpassende accessoires",
  "Product added to cart": "Product is toegevoegd aan de winkelwagen",
  "Place review": "Review plaatsen",
  "stars": "sterren",
  "star": "ster",
  "Categories": "Categorieën",
  "Products per page": "Producten per pagina",
  "Title": "Titel",
  "Description": "Beschrijving",
  "Name": "Naam",
  "Choose a service": "Kies voor Expert Service",
  "Price": "Prijs",
  "Enter a correct value": "Voer een correcte waarde in",
  "explain": "uitleg",
  "Show all results for": "Toon alle resultaten voor",
  "Select your address": "Selecteer jouw adres",
  "Add new address": "Nieuw adres",
  "How many stars would you give this product": "Hoeveel sterren zou je dit product geven",
  "We could not load the payment methods, please check your shipping method.": "We konden de betaalmethoden niet laden, controleer de verzendmethode.",
  "You have an account. Would you like to login?" : "Je hebt een account. Wil je inloggen?",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Het aanmelden voor het account was onjuist of uw account is tijdelijk uitgeschakeld. Wacht even en probeer het later opnieuw.",
  "The requested qty exceeds the maximum qty allowed in shopping cart": "De gevraagde hoeveelheid is groter dan de maximaal toegestane hoeveelheid in het winkelwagentje",
  "Review has been posted, after checking we will publish your review": "Review is geplaatst, na controle zullen wij uw review publiceren.",
  "Unable to place review": "De review kan niet worden geplaatst",
  "Number of stars are missing": "Aantal sterren ontbreekt",
  "Please wait, your coupon will be checked": "Even geduld, uw coupon wordt gecontroleerd",
  "Your account has been successfully created!" : "Je account is succesvol aangemaakt!",
  "Something went wrong while creating your account, please try again later." : "Er is iets misgegaan bij het aanmaken van je account, probeer het later opnieuw.",
  "Your account has been created, here you can view your order and track the progress of the order. Click on login to view your account." : "Je account is aangemaakt, hier kun je je bestelling bekijken en de voortgang van de bestelling volgen. Klik op inloggen om je account te bekijken.",
  "Is your Expert store of choice not listed?": "Staat uw Expert winkel naar keuze er niet tussen?",
  "The product may still be in stock in our central warehouse. You can simply order the product, the Expert Shop will contact you after your online order to agree on a delivery or collection time.": "Het product kan nog steeds op voorraad zijn in ons centrale magazijn. U kunt het product eenvoudig bestellen, de Expert Shop neemt na uw online bestelling contact met u op om een bezorg- of afhaaltijd af te spreken.",
  "Is this product expected at the Expert store near you?": "Wordt dit product verwacht bij de Expert winkel bij u in de buurt?",
  "The store can inform you about the expected delivery time.": "De winkel kan u informeren over de verwachte levertijd.",
  "Suggestions": "Zoeksuggesties",
  "Popular products": "Populaire producten",
  "What else can we do for you?": "Wat kunnen wij nog meer voor u doen?",
  'Do you have a promotional code?' : 'Heeft u een kortingscode?',
  'Apply' : 'Toepassen',
  "Cancel": 'Annuleren',
  'Your applied coupon code is {couponCodeInput}.' : 'Uw toegepaste kortingscode is {couponCodeInput}.',
  "Click here to automatically retrieve your address": "Klik hier om uw adres automatisch op te halen",
  "Loading address...": "Adres ophalen...",
  "We will contact you after your online order to arrange a collection time." : "Wij nemen na uw online bestelling contact met u op om een afhaalmoment/-tijd af te spreken.",
  "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
  "Your order will be delivered by" : "Uw bestelling wordt bezorgd door",
  'Expected delivery date' : "Verwachte bezorgdatum",
  "Delivery in consultation. You will be contacted" : "Bezorging in overleg. Er wordt contact met u opgenomen",
  "You can pick up your order at" : "U kunt uw bestelling ophalen bij",
  "Your personal data" : "Uw persoonlijke gegevens",
  "Selected services": "Gekozen servicediensten",
  "Delete" : "Verwijderen",
  "Bundle added to cart": "Bundel is toegevoegd aan winkelwagen",
  "Could not verify customer with given information.": "Wij kunnen uw account helaas niet activeren.",
  "Your account has been verified.": "Je account is gevalideerd en kunt nu inloggen."
}
